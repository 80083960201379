
import { defineComponent, toRefs, ref } from "vue";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import { AnimalTagAddModel } from "@/domain/animalTag/animalTagAdd/model/AnimalTagAddModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const animalTagAddModel = ref<AnimalTagAddModel>({
      name: "",
      description: "",
    });

    //Create form validation object
    const form = Yup.object().shape({
      name: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Name"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
    });

    const animalTagController =
      store.state.ControllersModule.animalTagController;

    if (!animalTagController) {
      throw new Error("AnimalTagController is not available in Vuex store.");
    }

    const submit = () => {
      loading.value = true;

      animalTagController
        .addAnimalTag(animalTagAddModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newAnimalTag", store.getters.newAnimalTag + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_TAG),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      form,
      animalTagAddModel,
      submit,
      loading,
      newTargetModalRef,
    };
  },
});
