
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/presentation/helper/Dom";

import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import AddAnimalTag from "@/presentation/components/AddAnimalTag.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { AnimalTagRemoveModel } from "@/domain/animalTag/animalTagRemove/model/AnimalTagRemoveModel";
import { AnimalTagUpdateModel } from "@/domain/animalTag/animalTagUpdate/model/AnimalTagUpdateModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_VACCINATION,
  components: { AddAnimalTag },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const animalTagList = ref<AnimalTagListModel[]>([]);
    const originalAnimalTagList = ref<AnimalTagListModel[]>([]);
    const animalTag = ref<AnimalTagUpdateModel>({});
    const swalNotification = new SwalNotification();

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const animalTagController =
      store.state.ControllersModule.animalTagController;

    watch(
      () => store.getters.newAnimalTag,
      () => {
        if (store.getters.newAnimalTag) getAnimalTags(1);
      }
    );

    const getAnimalTags = async (page) => {
      isLoading.value = true;
      animalTagList.value = [];
      originalAnimalTagList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              totalItems.value = animalTag.pagination.total;
              currentPage.value = animalTag.pagination.page;
              pageCount.value = animalTag.pagination.pageCount;
              animalTagList.value.push(animalTag);
              originalAnimalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeTag = async (id: number | undefined) => {
      const animalTagRemove: AnimalTagRemoveModel = {
        id: id,
      };
      swalNotification
        .withButtons(
          "Emin misiniz?",
          "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
          "Evet",
          "Hayır"
        )
        .then((result) => {
          if (result.isConfirmed) {
            animalTagController
              .removeAnimalTag(animalTagRemove)
              .then((response) => {
                if (response.isSuccess) {
                  store.dispatch("newAnimalTag", store.getters.newAnimalTag + 1 );
                  Swal.fire({
                    text: t(SWAL_MESSAGES.SUCCESS_REMOVE_ANIMAL_TAG),
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                  });
                } else {
                  swalNotification.error(
                    response.error.cause.cause +
                      t(SWAL_MESSAGES.ERROR_CODE) +
                      response.error.code +
                      response.error.cause.code,
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                }
              })
              .catch((e) => {
                swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
              });
          }
        });
    };

    const updateTag = async (tag: AnimalTagUpdateModel) => {
      animalTag.value = { ...tag };
    };

    const submit = () => {
      loading.value = true;

      animalTagController
        .updateAnimalTag(animalTag.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL_TAG),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      await nextTick();
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_TAGS));
      getAnimalTags(1);
    });

    return {
      PAGE_TITLE_PATH,
      loading,
      submit,
      getAnimalTags,
      animalTagList,
      originalAnimalTagList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      animalTag,
      removeTag,
      updateTag,
    };
  },
});
