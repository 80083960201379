<template>
  <div class="card">
    <div class="card-header border-0 pt-3">
      <div class="card-title">
        {{ $t(PAGE_TITLE_PATH.ANIMAL_TAGS) }}
        <!--
          <div
            v-if="originalVaccinationList.length != 0"
            class="d-flex align-items-center position-relative my-1"
          >
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <inline-svg src="/media/icons/duotune/general/gen021.svg" />
            </span>
            <input
              type="text"
              v-model="search"
              @input="searchItems()"
              class="
                form-control form-control-solid form-control-sm
                w-180px
                ps-10
              "
              style="margin-left: 15px"
              :placeholder="$t('menuPages.vaccineList.search')"
            />
          </div>
          -->
      </div>
      <div class="d-flex align-items-center py-1">
        <!--begin::Button-->
        <a
          v-if="animalTagList.length > 0"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_animal_tag_modal"
          id="kt_toolbar_primary_button"
        >
          {{ $t("menuPages.animalTags.button.add") }}
        </a>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="animalTagList.length != 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.animalTags.name") }}</td>
                <td>{{ $t("menuPages.animalTags.description") }}</td>
                <td>{{ $t("menuPages.animalTags.isDefault") }}</td>
                <td>{{ $t("common.table.process") }}</td>
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr
                class="odd"
                v-for="(animalTag, index) in animalTagList"
                :key="animalTag.animalTag.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ animalTag.animalTag.name }}</td>
                <td>{{ animalTag.animalTag.description }}</td>
                <td>
                  <span
                    v-if="!animalTag.animalTag.isDefault"
                    class="badge fs-8 fw-bolder badge-light-warning"
                  >
                    {{ $t("common.button.no") }}
                  </span>
                  <span v-else class="badge fs-8 fw-bolder badge-light-success">
                    {{ $t("common.button.yes") }}
                  </span>
                </td>
                <td>
                  <a
                    v-if="!animalTag.animalTag.isDefault"
                    @click="updateTag(animalTag.animalTag)"
                    class="
                      btn btn-icon btn-warning btn-active-color-primary btn-sm
                      me-1
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#update_animal_tag_modal"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>

                  <a
                    v-if="!animalTag.animalTag.isDefault"
                    @click="removeTag(animalTag.animalTag.id)"
                    class="
                      btn btn-icon btn-danger btn-active-color-light btn-sm
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="/media/icons/duotune/general/gen040.svg"
                      />
                    </span>
                  </a>

                  <a v-if="animalTag.animalTag.isDefault">{{
                    $t("common.table.notProcess")
                  }}</a>
                </td>
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <!--
            <div class="text-center px-5 pb-7">
              <img
                src="/media/animal/animal.jpg"
                
                class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
              />
            </div>
            -->
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.animalTags.zeroCount") }}</span>
          </p>
          <!--end::Description-->
          <!--begin::Button-->
          <a
            href="#"
            class="btn btn-sm btn-primary"
            id="kt_toolbar_primary_button"
            data-bs-toggle="modal"
            data-bs-target="#add_animal_tag_modal"
          >
            {{ $t("menuPages.animalTags.button.add") }}
          </a>
          <!--end::Button-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="originalAnimalTagList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getAnimalTags(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getAnimalTags(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getAnimalTags(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getAnimalTags(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
  </div>
  <AddAnimalTag></AddAnimalTag>

  <div
    class="modal fade"
    id="update_animal_tag_modal"
    ref="newTargetModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <el-form
            @submit.prevent="submit()"
            :model="animalTag"
            :rules="null"
            ref="formRef"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("menuPages.addAnimalTag.pageName") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                {{ $t("menuPages.addAnimalTag.pageDescription") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addAnimalTag.animalTag")
                }}</label>

                <el-form-item prop="name">
                  <el-input
                    v-model="animalTag.name"
                    type="text"
                    :placeholder="$t('menuPages.addAnimalTag.enterAnimalTag')"
                  ></el-input>
                </el-form-item>
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">{{
                    $t("menuPages.addAnimalTag.description")
                  }}</span>
                </label>
                <!--end::Label-->

                <el-form-item prop="description">
                  <el-input
                    v-model="animalTag.description"
                    :placeholder="$t('menuPages.addAnimalTag.enterDescription')"
                    type="text"
                  ></el-input>
                </el-form-item>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.save") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </el-form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/presentation/helper/Dom";

import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import AddAnimalTag from "@/presentation/components/AddAnimalTag.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { AnimalTagRemoveModel } from "@/domain/animalTag/animalTagRemove/model/AnimalTagRemoveModel";
import { AnimalTagUpdateModel } from "@/domain/animalTag/animalTagUpdate/model/AnimalTagUpdateModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_VACCINATION,
  components: { AddAnimalTag },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const animalTagList = ref<AnimalTagListModel[]>([]);
    const originalAnimalTagList = ref<AnimalTagListModel[]>([]);
    const animalTag = ref<AnimalTagUpdateModel>({});
    const swalNotification = new SwalNotification();

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const animalTagController =
      store.state.ControllersModule.animalTagController;

    watch(
      () => store.getters.newAnimalTag,
      () => {
        if (store.getters.newAnimalTag) getAnimalTags(1);
      }
    );

    const getAnimalTags = async (page) => {
      isLoading.value = true;
      animalTagList.value = [];
      originalAnimalTagList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              totalItems.value = animalTag.pagination.total;
              currentPage.value = animalTag.pagination.page;
              pageCount.value = animalTag.pagination.pageCount;
              animalTagList.value.push(animalTag);
              originalAnimalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const removeTag = async (id: number | undefined) => {
      const animalTagRemove: AnimalTagRemoveModel = {
        id: id,
      };
      swalNotification
        .withButtons(
          "Emin misiniz?",
          "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
          "Evet",
          "Hayır"
        )
        .then((result) => {
          if (result.isConfirmed) {
            animalTagController
              .removeAnimalTag(animalTagRemove)
              .then((response) => {
                if (response.isSuccess) {
                  store.dispatch("newAnimalTag", store.getters.newAnimalTag + 1 );
                  Swal.fire({
                    text: t(SWAL_MESSAGES.SUCCESS_REMOVE_ANIMAL_TAG),
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                  });
                } else {
                  swalNotification.error(
                    response.error.cause.cause +
                      t(SWAL_MESSAGES.ERROR_CODE) +
                      response.error.code +
                      response.error.cause.code,
                    t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                  );
                }
              })
              .catch((e) => {
                swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
              });
          }
        });
    };

    const updateTag = async (tag: AnimalTagUpdateModel) => {
      animalTag.value = { ...tag };
    };

    const submit = () => {
      loading.value = true;

      animalTagController
        .updateAnimalTag(animalTag.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL_TAG),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      await nextTick();
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_TAGS));
      getAnimalTags(1);
    });

    return {
      PAGE_TITLE_PATH,
      loading,
      submit,
      getAnimalTags,
      animalTagList,
      originalAnimalTagList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      animalTag,
      removeTag,
      updateTag,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
